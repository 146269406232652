/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

/*-----------------*/

@font-face {
	font-family: 'DMSerifDisplay';
	src: url('./assets/fonts/DMSerifDisplay-Regular.ttf');
	font-style: normal;
}

@font-face {
	font-family: 'Lato';
	src: url('./assets/fonts/Lato-Regular.ttf');
	font-weight: 400;
}
@font-face {
	font-family: 'Lato';
	src: url('./assets/fonts/Lato-Bold.ttf');
	font-weight: 600;
}

.App {
	min-height: 100vh;
	max-height: 100vh;
	overflow: hidden;
	position: relative;
}

h1 {
  font-family: 'DMSerifDisplay', serif;
  font-size: 20pt;
}

h2 {
	font-family: 'Lato', sans-serif;
	font-weight: 600;
	font-size: 16pt;
}

h3 {
	font-family: 'Lato', sans-serif;
	font-weight: 400;
	font-size: 12pt;
}

h4 {
	font-family: 'DMSerifDisplay', serif;
	font-size: 16pt;
}

p {
	font-family: 'Lato', serif;
	font-size: 12pt;
	font-weight: 400;
}

a {
	color: #175676;
	text-decoration: underline;
}

label {
	font-family: 'Lato', sans-serif;
	font-size: 12pt;
}

input {
	font-family: 'Lato', sans-serif;
	border: 1px solid;
	font-size: 12pt;
	box-sizing: border-box;
	height: 25px;
	padding: 0px 10px;
	border-radius: 5px;
}

img {
	box-shadow: 0px 0px 10px #000;
	border-radius: 5px;
}

button {
	font-family: 'DMSerifDisplay';
	font-size: 14pt;
	max-width: 35vw;

	color: white;
	background-color: #175676;
	padding: 3px 10px;
	border: none;
}

.background {
	width: 100%;
	height: 100%;
	background-color: white;
	display: inline-block;
	position: absolute;
	z-index: -1;
}

.page-header {
	margin-top: 3vh;
	margin-bottom: 7pt;
	padding-bottom: 4pt;
	border-bottom: 1px solid;
	width: 65vw;
}

.underline {
	text-decoration: underline 1px;
	text-underline-offset: 4pt;
}


/* Nav */

nav {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 50px;
	color: black;
	background-color: white;
	box-shadow: 0px 0px 10px #000;
	
	z-index: 2;
}

.nav-list {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
}

.nav-list-item {
	font-family: 'DMSerifDisplay', 'serif';
	font-size: 12pt;
	flex: 1;
	text-align: center;
	align-items: center;

	padding: 1vh 0;
	margin: 1vh 0;
	border-right: 1px solid;
}

.nav-edge {
	border-right: 0px;
}


/* Welcome Page */

.welcome-container {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	width: 65vw;
	padding: 15.5vh 17.5vw
}

.welcome-header {
	margin-bottom: 6pt;
}

.welcome-button {
	margin-top: 6pt;
	align-self: flex-end;
}

.welcome-paragraph {
	margin-bottom: 6pt;
}

/* Login */

.login-container {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	width: 65vw;
	padding: 31vh 17.5vw
}

.login-form {
	display: flex;
	flex-direction: column;
}

.login-label {
	display: block;
	margin-bottom: 12pt;
}

.login-input {
	display: block;
	width: 100%;
	margin-top: 3pt;
}

.login-controls {
	display: flex;
	justify-content: space-between;
}

.login-button {
	margin-bottom: 6pt;
}

/* MovieDetails */

.home-container {
	margin: 5.5vh 0;
	height: 100vh;
}

.movie-showcase {
	margin: 2.5vh 1.8vw;

	max-height: 100vh;
}

.movie-display {
	display: flex;
	flex-direction: column;
	width: 43vh;
	margin-left: calc(50vw - ((43vh / 3)));
}

.movie-award {
	align-self: flex-start;
	margin: 10pt 0 8pt 0;
}

.movie-poster {
	max-height: 43vh;
	align-self: flex-start;
}

.movie-description {
	width: 65vw;
}

.movie-title {
	margin: 6pt 0;
}


/* Rankings */
/* Dropdown categories */
.ranking-container {
	display: flex;
	flex-direction: column;
	margin: 2.5vh 1.8vw;

	max-height: 100vh
}

.category-list {
	display: flex;
	padding-bottom: 6pt;
}

.category-item {
	padding: 5px 5px 5px 0;
}

.movie-ranking-list {
	display: flex;
	flex-wrap: wrap;

	overflow: scroll;
	margin-bottom: 50px;
	padding-bottom: 50px;
}

.movie-ranking-item {
	display: flex;
	flex-direction: column;
	width: 47vw;
	overflow: hidden;
	align-items: center;
	margin-top: 2.6vh;
}

.movie-ranking-poster {
	height: 21vh;
	margin-top: calc(3.2vh * -0.5)
}

.movie-ranking-title {
	width: calc(0.675*21vh);

	font-size: 12pt;
}

.movie-ranking {
	background-color: #175676;
	color: white;
	width: 3.2vh;
	height: 3.2vh;
	line-height: 3.2vh;
	text-align: center;
	align-items: center;
	border-radius: 50%;

	margin-right: calc((0.675*21vh));
	z-index: 1;
}


/* My Ratings */

.unrated-container {
	display: flex;
	flex-direction: column;
	margin: 2.5vh 1.8vw;

	max-height: 100vh;
}

.unrated-list {
	display: flex;
	flex-wrap: wrap;
	overflow: scroll;
	margin-bottom: 50px;
	padding: 5px 0 50px 0;
}

.unrated-item {
	display: flex;
	justify-content: center;
	width: calc(96.4vw/3);
	margin: 0.5vh 0;
}

.unrated-poster {
	height: 21vh;
}


/* Modal */

.modal {
	position: absolute;
	background-color: rgba(0, 0, 0, 0.3);

	width: 100vw;
	height: 100vh;

	top: 0;
	z-index: 1;
}

.modal-container {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: white;
	width: 95vw;
	border-radius: 2vh;

	display: flex;
	flex-direction: column;
}

.modal-close {
	position: fixed;
	right: 2vh;
	top: 2vh;
}

.modal-content {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	
}

.modal-info {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: center;
	padding-top: 5vh;
}

.modal-header {
	font-weight: 600;
	font-size: 14pt;
}

.modal-movie-details {
	width: 40vw;
	margin-left: 0.5vh;
}

.modal-movie-poster {
	height: 32vh;
	margin-right: 0.5vh;
}

.movie-overview {
	max-height: 15vh;
	overflow: scroll;
	padding-bottom: 6pt;
	margin-bottom: 3pt;
}

.modal-rating-form {
	display: flex;
	flex-direction: column;
	width: calc(40vw + (64vh / 3) + 1vh);

	margin: 3vh 0 0 0;
}

.modal-rating-input-container {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	padding: 2vh 12vw;

	border-top: 1px solid;
	border-bottom: 1px solid;
}

.modal-rating-input {
	display: flex;
	flex-direction: column;

	margin: 0.2vh;
	width: 8vh
}

.modal-rating-label {
	padding: 3px 0;
}

.modal-rating-summary {
	display: flex;
	justify-content: space-between;
	padding: 0 12vw;
	margin: 1vh 0 5vh 0;
	align-items: flex-end;
}

.modal-submit-button {
	height: 35px;
	width: 110px;
}